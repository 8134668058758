/*----------------------------------------------
    # Nav bar
----------------------------------------------*/
.navbar-top {
  background: #091d3e;
  padding: 15px 0;
  ul {
    margin: 0;
    padding: 0;
    line-height: initial;
    li {
      display: inline-block;
      list-style: none;
      margin-right: 25px;
      &:last-child {
        margin-right: 0;
        padding-right: 0;
        border-right: 0;
      }
      p,
      a {
        margin: 0;
        font-size: 14px;
        color: #fff;
        img {
          margin-right: 5px;
        }
        i {
          margin-right: 5px;
        }
        svg {
          margin-right: 5px;
        }
      }
      a:hover {
        color: var(--heading-color);
      }
    }
  }
  .topbar-right {
    a {
      margin: 0 3px;
    }
    li {
      a {
        i {
          margin-right: 0;
        }
        svg {
          margin-right: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
}

.navbar-area {
  position: relative;
  z-index: 99;
  margin-top: 20px;
  .nav-container {
    transition: all 0.4s;
    position: relative;
    &.navbar-bg {
      position: relative;
      padding-left: 40px;
      &:after {
        content: "";
        position: absolute;
        left: 15px;
        top: 0;
        height: 100%;
        width: 95%;
        background: #fff;
        z-index: -1;
      }
    }
    .logo {
      line-height: 0.9;
      a {
        font-weight: 700;
        font-size: 24px;
        color: #fff;
        img {
          height: auto;
          max-width: 250px;
        }
      }
    }
    .btn-transparent {
      font-size: 13px;
      font-weight: 700;
    }
    .navbar-collapse {
      .navbar-nav {
        display: block;
        width: 100%;
        text-align: left;
        li {
          a {
            color: var(--heading-color);
            font-weight: 600;
          //  font-size: 12px;
            font-family: var(--heading-font);
          }
          &:hover a {
            color: var(--main-color);
          }
          + li {
            margin-left: 10px;
          }
          display: inline-block;
          font-weight: 500;
          line-height: 80px;
          text-transform: capitalize;
          &.menu-item-has-children {
            position: relative;
            z-index: 0;
            padding-right: 14px;
            &:before {
              content: "";
              position: absolute;
              right: 3px;
              top: 50%;
              height: 10px;
              width: 2px;
              background: var(--heading-color);
              transform: translateY(-50%);
              transition: all 0.3s ease-in;
              margin-top: 0;
              z-index: -1;
            }
            &:after {
              content: "";
              position: absolute;
              right: -1px;
              top: 40px;
              height: 2px;
              width: 10px;
              background: var(--heading-color);
              transform: translateY(-50%);
              transition: all 0.3s ease-in;
              z-index: -1;
            }
            &:hover {
              @include transition(all, 0.4s, ease);
              &:before {
                opacity: 0;
              }
              &:after {
                background: var(--main-color);
              }
            }
            &:hover > .sub-menu {
              visibility: visible;
              opacity: 1;
            }
            .sub-menu {
              position: absolute;
              text-align: left;
              min-width: 210px;
              margin: 0;
              padding: 0;
              list-style: none;
              left: 0;
              top: 100%;
              box-shadow: 0 0 15px 0 rgba(#000, 0.05);
              background-color: $color-white;
              z-index: 9;
              overflow: hidden;
              visibility: hidden;
              opacity: 0;
              //   &.active {
              //     visibility: visible;
              //     opacity: 1;
              //     display: block;
              //   }
              @include transition(all, 0.4s, ease);
              border-radius: 0;
              &.border-bt0 {
                border-bottom: 0px !important;
              }
              li {
                display: block;
                margin-left: 0;
                line-height: 22px;
                font-size: 15px;
                @include transition(all, 0.4s, ease);
                border-bottom: 1px solid #f5f5f5;
                a {
                  display: block;
                  padding: 10px 20px;
                  white-space: nowrap;
                  transition: all 0.3s;
                  color: #050a30;
                  font-size: 14px;
                  font-weight: 500;
                  @include transition(all, 0.4s, ease);
                  position: relative;
                }
                &:hover {
                  background: #061539;
                  a {
                    color: #fff;
                    padding: 10px 20px 10px 30px;
                    &:before {
                      visibility: visible;
                      opacity: 1;
                    }
                  }
                }
                &:last-child {
                  border-bottom: 0;
                }
              }
              .menu-item-has-children {
                position: relative;
                z-index: 0;
                padding-right: 0px;
                &:before {
                  position: absolute;
                  right: 15px;
                  top: 50%;
                  content: "\f105";
                  font-family: "fontawesome";
                  @include transform(translateY(-50%));
                }
                > .sub-menu {
                  left: 100%;
                  top: 20px;
                  .sub-menu {
                    .sub-menu {
                      left: auto;
                      right: 100%;
                    }
                  }
                }
                &:hover > .sub-menu {
                  visibility: visible;
                  opacity: 1;
                  li {
                    &:hover {
                      &:before {
                        color: $color-white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        > li {
          margin-right: 12px;
        }
      }
    }
  }
}

.navbar-nav {
  opacity: 0;
  margin-right: -30px;
  visibility: hidden;
  transition: all 0.4s ease 0s;
}

.navbar-area-2 {
  margin-top: 0;
  &:after {
    display: none;
  }
}

//sticky-style here
.sticky-active {
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  left: 0;
   position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  -webkit-box-shadow: 0 10px 20px 0 rgb(46 56 220 / 5%);
  box-shadow: 0 10px 20px 0 rgb(46 56 220 / 5%);
  border-bottom: 0;
  background: #fff;
}

.menu-open {
  opacity: 1;
  margin-right: 0;
  visibility: visible;
}
.bar1 {
  width: 32px;
  height: 2px;
  margin-bottom: 5px;
  position: absolute;
  background: $color-white;
  z-index: 9999;
  top: 10px;
  right: -5px;
}
.bar2 {
  width: 24px;
  height: 2px;
  margin-bottom: 5px;
  position: absolute;
  background: $color-white;
  z-index: 9999;
  top: 17px;
  right: -5px;
}
.bar3 {
  width: 18px;
  height: 2px;
  margin-bottom: 5px;
  position: absolute;
  background: $color-white;
  z-index: 9999;
  top: 24px;
  right: -5px;
}
.responsive-mobile-menu button:focus {
  outline: none;
  border: none;
}

/**************** nav-left-part *************/
.header-cat-menu {
  margin: 0;
  padding: 0;
  margin-left: 58px;
  li {
    position: relative;
    margin-right: 0;
    cursor: pointer;
    list-style: none;
    img {
      margin-top: -3px;
      margin-right: 5px;
    }
    a {
      color: #022334;
      font-weight: 500;
      font-size: 18px;
    }
    .sub-menu {
      position: absolute;
      left: 0;
      top: 40px;
      list-style: none;
      padding: 0;
      margin: 0;
      z-index: 9;
      border: none;
      opacity: 0;
      visibility: hidden;
      transition: 0.4s;
      box-shadow: 0 0 15px 0 rgb(0 0 0 / 5%);
      background-color: #fff;
      min-width: 210px;
      li {
        display: block;
        margin-left: 0;
        line-height: 22px;
        font-size: 15px;
        @include transition(all, 0.4s, ease);
        border-bottom: 1px solid #f5f5f5;
        a {
          display: block;
          padding: 10px 20px;
          white-space: nowrap;
          transition: all 0.3s;
          color: #050a30;
          font-size: 14px;
          font-weight: 500;
          @include transition(all, 0.4s, ease);
          position: relative;
          &:before {
            position: absolute;
            left: 17px;
            top: 50%;
            content: "\f105";
            font-family: "fontawesome";
            @include transform(translateY(-50%));
            visibility: hidden;
            opacity: 0;
            transition: 0.4s;
            color: #fff;
          }
        }
        &:hover {
          background: #061539;
          a {
            color: #fff;
            padding: 10px 20px 10px 30px;
            &:before {
              visibility: visible;
              opacity: 1;
            }
          }
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    &:hover {
      > a {
        color: var(--main-color);
      }
      ul {
        opacity: 1;
        visibility: visible;
        animation: menuslide 300ms;
      }
    }
  }
}

/**************** nav-right-part *************/
.nav-right-part {
  .navbar-phone {
    height: 80px;
    color: #fff;
    border-radius: 0;
    position: relative;
    display: inline-block;
    background: var(--main-color);
    padding: 11px 25px 11px 85px;
    .icon {
      position: absolute;
      left: 30px;
      background: rgba(255, 255, 255, 0.1);
      display: inline-block;
      height: 50px;
      width: 50px;
      line-height: 47px;
      text-align: center;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
    }
    span {
      font-weight: 500;
      color: #fff;
      display: inline-block;
      margin-bottom: 2px;
      font-size: 12px;
    }
    h5 {
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 0;
    }
  }

  .btn {
   // padding: 0 10px;
    //  font-size: x-small;
  }

  a {
    margin-left: 15px;
    color: var(--heading-color);
  }
  .search-bar-btn {
    margin-top: -22px;
    cursor: pointer;
  }
  .search-bar {
    &:hover {
      background: var(--main-color);
      border: 1px solid var(--main-color);
    }
  }
}
.navbar-area-2 .nav-right-part {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-area-2 .nav-right-part .navbar-phone {
  color: var(--heading-color);
  background: transparent;
}
.navbar-area-2 .nav-right-part .navbar-phone .icon {
  background: rgba(36, 107, 253, 0.1803921569);
}
.navbar-area-2 .nav-right-part .navbar-phone .icon img {
  -webkit-filter: invert(38%) sepia(100%) saturate(3579%) hue-rotate(212deg)
    brightness(97%) contrast(105%);
  filter: invert(38%) sepia(100%) saturate(3579%) hue-rotate(212deg)
    brightness(97%) contrast(105%);
}
.navbar-area-2 .nav-right-part .navbar-phone h5,
.navbar-area-2 .nav-right-part .navbar-phone span {
  color: var(--heading-color);
}

.navbar-area-3 {
  .nav-container .navbar-collapse .navbar-nav li a {
    color: #fff;
  }
  .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before,
  .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:after {
    background: #fff;
  }
}
.navbar-area-3 .navbarFive {
  li {
    &:hover {
      & > a {
        color: #50af9b !important;
      }
    }
    &:hover:before {
      background: #50af9b !important;
    }
    &:hover::after {
      background: #50af9b !important;
    }
    .sub-menu {
      li {
        &:hover {
          background: #50af9b !important;
          & > a {
            color: #fff !important;
          }
        }
      }
    }
  }
}
.nav-right-part-desktop {
  margin-left: 10px;
}
.nav-right-part-mobile {
  display: none;
}

/**************** sidebar-menu *************/
.sidebar-menu {
  width: 0;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: #fff;
  z-index: 999;
  overflow-y: auto;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.sidebar-menu .sidebar-inner {
  position: relative;
  top: 150px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  padding: 90px 40px 50px 40px;
  .thumb {
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 28px;
  }
  .sidebar-address {
    margin-bottom: 35px;
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin-bottom: 4px;
        &:last-child {
          margin-bottom: 0;
        }
        i {
          margin-right: 6px;
          width: 25px;
        }
      }
    }
  }
  .sidebar-subscribe {
    position: relative;
    margin-bottom: 40px;
    input {
      width: 100%;
      border: 1px solid #d6dadf;
      height: 42px;
      padding: 0 45px 0 12px;
    }
    button {
      position: absolute;
      right: 0;
      top: 0;
      height: 42px;
      width: 42px;
      background: #97b3d0;
      transition: 0.4s;
      border: 0;
      color: var(--heading-color);
      cursor: pointer;
      &:hover {
        background: var(--main-color);
        color: #fff;
      }
    }
  }
  .social-media li a {
    background: #ced4da;
  }
  .social-media li a:hover {
    background: var(--main-color);
    color: #fff;
  }
}
.sidebar-menu .sidebar-menu-close {
  background-color: var(--main-color);
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  z-index: 999;
}
.sidebar-menu.active {
  width: 360px;
  opacity: 1;
  visibility: visible;
}
.sidebar-menu.active .sidebar-inner {
  top: 0;
  opacity: 1;
  visibility: visible;
  transition: all 0.7s ease-out 0.8s;
}

@media only screen and (max-width: 991px) {
  .navbar-area {
    background: #fff;
  }
  .navbar-area .nav-container {
    padding: 5px 0;
  }
  .nav-right-part {
    margin-right: 60px;
  }
  .nav-right-part .btn .right {
    padding-left: 5px;
    font-size: 13px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav > li {
    margin-right: 0;
    padding-right: 0;
  }
  .navbar-area {
    .nav-container {
      position: relative;
      z-index: 0;
      .navbar-toggler {
        padding: 0px;
      }
      .navbar-collapse {
        margin-top: 13px;

        .navbar-nav {
          display: block;
          margin-top: 20px;
          li {
            a {
              display: block;
            }
            display: block;
            text-align: left;
            line-height: 30px;
            padding: 10px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            &:last-child {
              border-bottom: none;
            }
            + li {
              margin-left: 0;
            }
            &.menu-item-has-children {
              &:before {
                top: 26px;
                right: 3px !important;
              }
              .sub-menu {
                position: initial;
                display: block;
                width: 100%;
                border-top: none;
                box-shadow: none;
                margin-left: 0;
                padding-bottom: 0;
                height: auto;
                overflow: hidden;
                max-height: 0;
                overflow-y: hidden;
                background-color: transparent;
                border-radius: 10px;
                padding: 0px;
                border-bottom: none;
                transition: none;
                visibility: visible;
                opacity: 1;
                transition: all 0.5s linear;
                &.active {
                  visibility: visible;
                  opacity: 1;
                  max-height: 250px;
                }

                .sub-menu .menu-item-has-children:before {
                  content: "\f107";
                }
                li {
                  &.menu-item-has-children {
                    &:hover {
                      &:before {
                        top: 30px;
                        color: $color-white;
                      }
                    }
                  }
                  padding: 0;
                  + li {
                    border-top: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.sopen {
  display: block !important;
}

//toggle button css
.toggle-btn {
  left: auto;
  right: 0;
  position: absolute;
  top: 8px;
  width: 40px;
  height: 40px;
  transition-duration: 0.5s;
  border: 0;
  background: transparent;

  .icon-left {
    transition-duration: 0.5s;
    position: absolute;
    height: 2px;
    width: 11px;
    top: 18px;
    background-color: var(--heading-color);
    left: 7px;
    &:before {
      transition-duration: 0.5s;
      position: absolute;
      width: 11px;
      height: 2px;
      background-color: var(--heading-color);
      content: "";
      top: -7px;
      left: 0;
    }

    &:after {
      transition-duration: 0.5s;
      position: absolute;
      width: 11px;
      height: 2px;
      background-color: var(--heading-color);
      content: "";
      top: 7px;
      left: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .icon-right {
    transition-duration: 0.5s;
    position: absolute;
    height: 2px;
    width: 11px;
    top: 18px;
    background-color: var(--heading-color);
    left: 18px;

    &:before {
      transition-duration: 0.5s;
      position: absolute;
      width: 11px;
      height: 2px;
      background-color: var(--heading-color);
      content: "";
      top: -7px;
      left: 0;
    }

    &:after {
      transition-duration: 0.5s;
      position: absolute;
      width: 11px;
      height: 2px;
      background-color: var(--heading-color);
      content: "";
      top: 7px;
      left: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.open {
    .icon-left {
      transition-duration: 0.5s;
      background: transparent;

      &:before {
        transform: rotateZ(45deg) scaleX(1.4) translate(2px, 1px);
      }

      &:after {
        transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -1px);
      }
    }

    .icon-right {
      transition-duration: 0.5s;
      background: transparent;

      &:before {
        transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 1px);
      }

      &:after {
        transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -1px);
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    font-size: 16px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li + li {
    margin-left: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .responsive-mobile-menu {
    display: block;
    width: 100%;
    position: relative;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1199px) {
  .margin-xlt-80 {
    margin-top: 0px;
  }
  .contact-widget .contact_info_list li.single-info-item .details {
    padding-left: 25px;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-area .logo {
    padding-top: 0px !important;
  }
  .widget ul {
    text-align: left;
  }
  .navbar-collapse {
    background: $color-white;
    margin-top: 0px;
    width: 100%;
  }
  .navbar-area
    .nav-container
    .navbar-collapse
    .navbar-nav
    li.menu-item-has-children:before {
    right: 20px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav {
    margin-bottom: 20px;
  }
  .navbar-area {
    padding-bottom: 0px;
  }
  .bar1,
  .bar2,
  .bar3 {
    background: #fff;
  }
  .party-box-wrapper {
    padding: 50px 0px;
  }
  .party-box-wrapper .party-box-content h4 {
    font-size: 30px;
    line-height: 45px;
  }
  .footer-area .copyright-area-inner {
    padding: 20px;
  }

  .navbar-expand-lg .navbar-collapse {
    margin-top: 0px;
  }
  .contact-widget .contact_info_list li.single-info-item .details {
    padding-left: 25px;
  }
  .footer-area .footer-top .widget.widget_nav_menu ul li a {
    font-size: 14px;
  }
  .navbar-area
    .nav-container
    .navbar-collapse
    .navbar-nav
    li.menu-item-has-children
    .sub-menu {
    padding: 0 0 0 20px;
  }
  .navbar-area
    .nav-container
    .navbar-collapse
    .navbar-nav
    li.menu-item-has-children
    .sub-menu
    li
    a {
    padding: 12px 0;
  }
  .navbar-area
    .nav-container
    .navbar-collapse
    .navbar-nav
    li.menu-item-has-children
    .sub-menu
    li:last-child
    a {
    padding-bottom: 3px;
  }
  .navbar-area
    .nav-container
    .navbar-collapse
    .navbar-nav
    li.menu-item-has-children
    .sub-menu
    li
    a:hover
    i {
    opacity: 0;
    margin-right: -18px;
  }
  .nav-right-part-mobile {
    display: block;
  }
  .nav-right-part-desktop {
    display: none;
  }
  .navbar-area
    .nav-container
    .navbar-collapse
    .navbar-nav
    li.menu-item-has-children
    .sub-menu
    li:hover {
    background: none;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    color: #333333;
  }
}

/* Tablet Layout wide: 767px. */
@media only screen and (max-width: 767px) {
  .logo-wrapper.mobile-logo {
    display: block;
    width: 100%;
  }
  .responsive-mobile-menu {
    display: block;
    width: 100%;
    position: relative;
  }

  .responsive-mobile-menu .navbar-toggler {
    position: absolute;
    left: calc(100% - 130px);
    top: 10px;
  }
  .table-responsive {
    display: block !important;
  }
  .btn-custom-default,
  .btn-custom-white {
    font-size: 14px;
    line-height: 33px;
    padding: 6px 20px;
  }
  .navbar-area .logo {
    padding-top: 0px !important;
  }
}

/* medium tablet layout 599px */
@media only screen and (max-width: 575px) {
  .navbar-area .nav-container {
    margin: 0px 0px;
  }
  .navbar-area .logo {
    padding-top: 10px;
  }
  .widget.footer-widget .subscribe-form.subscribe-form-style2 .form-control {
    padding: 15px 20px;
  }
  .widget.footer-widget .subscribe-form.subscribe-form-style2 .btn {
    padding: 15px 20px;
  }
  .search-popup .search-form {
    min-width: 350px;
  }
}

@media only screen and (max-width: 375px) {
  .btn-custom-default,
  .btn-custom-white {
    padding: 5px 18px;
  }
  .search-popup .search-form .form-group .form-control,
  .search-popup .search-form .submit-btn {
    height: 45px;
  }
  .search-popup .search-form {
    min-width: 300px;
  }
}

@media only screen and (max-width: 320px) {
  .search-popup .search-form {
    min-width: 265px;
  }
  .responsive-mobile-menu .navbar-toggler {
    left: calc(100% - 95px);
  }
}
