/*--------------------------------------------------
    ##Footer style
---------------------------------------------------*/
.footer-area {
  position: relative;
  margin-top: 90px;
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: var(--heading-color);
    z-index: -1;
  }
  .widget {
    position: relative;
    z-index: 2;
  //  margin-bottom: 70px;
  }
  .widget-title {
    margin-bottom: 30px;
    position: relative;
  }
  .widget_about {
    .thumb {
      margin-bottom: 20px;
      img {
      //  max-width: 200px;
      }
    }
    .details {
      p {
        margin-bottom: 0;
        color: #fff;
        svg {
          color: var(--main-color);
          margin-right: 10px;
        }
        + p {
          font-weight: 500;
        }
      }
      .social-media {
        margin-top: 26px;
        li {
          a:hover {
            background: var(--main-color);
            color: #fff;
          }
        }
      }
    }
    .subscribe {
      position: relative;
      input {
        width: 100%;
        height: 44px;
        padding: 0 50px 0 15px;
        border-radius: 5px;
        border: 1px solid #e3e3e3;
        box-shadow: none;
        outline: 0;
      }
      button {
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0 5px 5px 0;
        height: 44px;
        width: 40px;
        border: 0;
        background: var(--main-color);
        color: #fff;
        font-size: 15px;
        transition: 0.4s;
        &:hover {
          background: var(--heading-color);
        }
      }
    }
  }
  .widget_nav_menu {
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin-bottom: 7px;
        position: relative;
        color: #fff;
        position: relative;
        svg {
          margin-right: 8px;
          color: #333f4d;
          transition: 0.4s;
        }
        &:hover {
          svg {
            color: var(--main-color);
          }
        }
        &.sky {
          &:hover {
            svg {
              color: #50af9b;
            }
            a {
              color: #50af9b;
            }
          }
        }
      }
    }
  }
  .widget_subscribe {
    .single-input-inner {
      position: relative;
      input {
        padding-right: 40px;
      }
    }
    button {
      padding: 0;
      border: 0;
      position: absolute;
      right: 15px;
      top: 10px;
      background: transparent;
      color: var(--main-color);
    }
    label {
      color: #fff;
      position: relative;
      padding-left: 22px;
      input {
        position: absolute;
        left: 0;
        top: 7px;
      }
    }
  }
  .widget-recent-post ul li .media .media-body .title {
    color: #fff;
  }
  .widget-recent-post ul li .media .media-body .title.sky a:hover {
    color: #50af9b;
  }
  .widget-recent-post ul li .media .media-body .post-info span {
    color: #fff;
  }
  .widget-recent-post ul li .media .media-body .post-info .sky {
    color: #50af9b;
  }
}

.footer-top {
  padding-top: 55px;
  padding-bottom: 90px;
}
.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  padding: 23px 0;
  position: relative;
  z-index: 2;
  p {
    margin-bottom: 0;
    color: #fff;
  }
  a {
    margin-right: 10px;
    color: #fff;
    &:last-child {
      margin-right: 0;
    }
  }
}

.footer-subscribe {
  transform: translateY(-90px);
  .footer-subscribe-inner {
    border-radius: 10px;
    padding: 40px;
    position: relative;
    h2 {
      color: #fff;
    }
    input {
      height: 55px;
      padding: 0 150px 0 20px;
      color: var(--heading-color);
      width: 100%;
      border: 0;
    }
    .btn {
      position: absolute;
      right: 30px;
    }
    &.style-2 {
      display: flex;
      text-align: center;
      margin-top: -90px;
      border-radius: 0;
      .media {
        width: 340px;
        padding: 30px;
      }
      p {
        font-size: 13px;
      }
      input {
        padding: 0 18px;
      }
      .btn {
        position: relative;
        right: auto;
      }
      .row {
        padding: 20px 30px;
      }
    }
  }
}

.footer-area-2 {

  .footer-t {
    border-top: 1px solid #e3e3e3;
  }
  
  .widget-title {
    color: var(--heading-color) !important;
  }
  p {
    color: var(--heading-color) !important;
    font-size: small;
  }
  a {
    color: var(--paragraph-color) !important;
  //  font-size: small;
    svg {
      color: var(--paragraph-color) !important;
    }
    &:hover {
      color: var(--main-color) !important;
    }
  }
  .widget_about {
  //  background: #fff;
  //  padding: 25px;
  }

  li {
  //  font-size: small;
  }
  .footer-bottom {
    border-top: 1px solid #e3e3e3;
  }
}
